import React, { useState, useEffect, useCallback } from 'react';
import { ThemeProvider, CircularProgress, ListItem } from '@material-ui/core';

import {
  TopoOutside,
  Topo,
  HeaderTitle,
  DivLogged,
  SignOutTitle,
  DivBody,
  NavLink,
} from './styles';
import theme from '../../styles/material';

import back from '../../assets/back.jpg';

import logo from '../../assets/logo.png';

import { useAuth } from '../../hooks/useAuth';
import { useBooks } from '../../hooks/useBooks';

const BooksLoaded: React.FC = (): any => {
  const { userBook } = useBooks();

  return (
    <>
      <h2>Available Books:</h2>
      <br />
      {userBook && userBook.books.length > 0 ? (
        <ul>
          {userBook.books.map(book => (
            <ListItem key={book.id}>
              <NavLink to={`/books/${book.id}`}>
                {book.name || 'Interchange'}
              </NavLink>
            </ListItem>
          ))}
        </ul>
      ) : (
        <h3>No books yet.</h3>
      )}
    </>
  );
};

const Books: React.FC = (): any => {
  const { loggedUser, signOut } = useAuth();
  const { loadBooks, isBooksLoading } = useBooks();

  const localLoadBooks = useCallback(async () => {
    await loadBooks('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    localLoadBooks();
  }, [localLoadBooks]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <TopoOutside img={back} />

        <div id="container">
          <Topo>
            <img src={logo} alt="Easy Talk Idiomas Online" />
            <DivLogged>
              <HeaderTitle>{`Hi, ${loggedUser?.name}!`}</HeaderTitle>
              <SignOutTitle onClick={() => signOut()}>Sign out</SignOutTitle>
            </DivLogged>
          </Topo>
          <DivBody>
            {isBooksLoading ? <CircularProgress /> : <BooksLoaded />}
          </DivBody>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Books;
