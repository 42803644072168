import styled from 'styled-components';

interface ICardOrderProps {
  order: number;
}

interface IBackgroundImageProps {
  img: string;
}

export const TopoOutside = styled.div`
  background: #410259;
  width: 100%;
  height: 190px;
  position: absolute;
  z-index: -1;
  background-image: url(${(props: IBackgroundImageProps) => props.img});
`;

export const Topo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopButtonContainer = styled.div`
  margin: 10px 0px;
`;

export const MainPhraseContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px 0px;
`;

export const MainPhrase = styled.text`
  font-size: 60px;
  font-weight: bold;
  text-align: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  position: relative;
  flex: 1;
  flex-direction: column;
  display: flex;
  /* background: #410259; */
  max-width: 650px;
  margin: 10px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  flex-flow: column;
  @media (max-width: 800px) {
    flex: 100%;
    max-width: 650px;
    order: ${(props: ICardOrderProps) => props.order};
  }
`;

export const CardTitle = styled.text`
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
`;

export const CardText = styled.text`
  font-size: 20px;
  text-align: center;
`;

export const Footer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  // background: #410259;
`;

export const FooterCompanyDetails = styled.div`
  margin-top: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
`;

export const FooterButtonsContainer = styled.div`
  justify-content: space-between;
  display: flex;
`;

export const FooterGroupedButtonsContainer = styled.div``;

export const FooterButton = styled.button`
  background: #fff;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 7px;
  border: 0;
  :hover {
    cursor: pointer;
  }
`;

export const FooterOutside = styled.div`
  background: #410259;
  width: 100%;
  height: 175px;
  position: absolute;
  z-index: -2;
  transform: translateY(-100%);
  background-image: url(${(props: IBackgroundImageProps) => props.img});
`;

export const CompanyDetailsText = styled.text`
  font-size: 16px;
`;

export const ButtonWrapper = styled.div`
  margin-top: 15px;
`;

export const fieldStyle = {
  marginTop: '15px',
};

export const MainTitleDiv = styled.div`
  margin-top: 50px;
  text-align: center;
`;

export default FooterOutside;
