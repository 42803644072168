import React, { useState, useEffect, useRef, useCallback } from 'react';

import { useParams } from 'react-router-dom';

import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {
  ThemeProvider,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';

import Button from '@material-ui/core/Button';

import {
  TopoOutside,
  Topo,
  CollapseWrapper,
  CollapseBody,
  HeaderTitle,
  DivLogged,
  SignOutTitle,
  AnswerDiv,
  NavLink,
} from './styles';
import theme from '../../styles/material';

import back from '../../assets/back.jpg';

import logo from '../../assets/logo.png';

import { IUnitsJsonProps, useUnit } from '../../hooks/useUnits';
import { useAuth } from '../../hooks/useAuth';
import { useBooks } from '../../hooks/useBooks';

export interface IParamsProps {
  id: string;
}

interface IDialogProps {
  isDialogOpen: boolean;
  unitName: string;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CustomDialog: React.FC<IDialogProps> = ({
  isDialogOpen,
  unitName,
  setIsDialogOpen,
}): any => {
  const { sendMail } = useUnit();

  return (
    <Dialog
      open={isDialogOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{`Send report for ${unitName}?`}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`A report will be sent to teacher Kelly, informing that you have finished the drills ${unitName}. Continue?`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsDialogOpen(false)} color="primary">
          No
        </Button>
        <Button
          onClick={() => {
            setIsDialogOpen(false);
            sendMail(unitName);
          }}
          color="primary"
          autoFocus
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ProcessUnits: React.FC = (): any => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { units, isEmailLoading } = useUnit();

  const unitName = useRef('');

  const handleReportClick = (unit: string): void => {
    unitName.current = unit;
    setIsDialogOpen(true);
  };

  return (
    <>
      {units.map(unit => (
        <Accordion key={unit.id}>
          <AccordionSummary
            aria-controls={`panel${unit.id}a-content`}
            id={`panel${unit.id}a-header`}
            style={{ backgroundColor: '#420259' }}
          >
            <HeaderTitle>{unit.name}</HeaderTitle>
          </AccordionSummary>
          <CollapseBody>
            {unit.audios.map(audio => (
              <React.Fragment key={audio.file}>
                <AudioPlayer
                  key={audio.file}
                  src={audio.file}
                  showJumpControls={false}
                  customAdditionalControls={[]}
                  header={audio.name}
                  preload="none"
                />
                <br />
              </React.Fragment>
            ))}
            {unit.answers.length > 0 && (
              <AnswerDiv>
                <h2>Answer Keys</h2>
                <br />
                {unit.answers.map(answer => (
                  <NavLink href={answer.file}>{answer.name}</NavLink>
                ))}
              </AnswerDiv>
            )}
            <br />
            <Button
              disabled={isEmailLoading}
              variant="contained"
              color="primary"
              onClick={() => handleReportClick(unit.name)}
            >
              {isEmailLoading ? (
                <CircularProgress />
              ) : (
                `I finished ${unit.name}`
              )}
            </Button>
          </CollapseBody>
        </Accordion>
      ))}
      <CustomDialog
        isDialogOpen={isDialogOpen}
        unitName={unitName.current}
        setIsDialogOpen={setIsDialogOpen}
      />
    </>
  );
};

const Units: React.FC = (): any => {
  const { loggedUser, signOut } = useAuth();
  const { loadBooks } = useBooks();
  const { isUnitsLoading, loadUnits } = useUnit();

  const { id } = useParams<IParamsProps>();

  const callbackLoadUnits = useCallback(async () => {
    // await loadBooks('../');
    await loadUnits(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    callbackLoadUnits();
  }, [callbackLoadUnits]);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <TopoOutside img={back} />

        <div id="container">
          <Topo>
            <img src={logo} alt="Easy Talk Idiomas Online" />
            <DivLogged>
              <HeaderTitle>{`Hi, ${loggedUser?.name}!`}</HeaderTitle>
              <SignOutTitle onClick={() => signOut()}>Sign out</SignOutTitle>
            </DivLogged>
          </Topo>
          <CollapseWrapper>
            {isUnitsLoading ? <CircularProgress /> : <ProcessUnits />}
          </CollapseWrapper>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Units;
