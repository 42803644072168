import React, { createContext, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import emailjs from 'emailjs-com';
import { useAuth } from './useAuth';
import { IBookProps, useBooks } from './useBooks';

interface IFileProps {
  name: string;
  file: string;
}

interface IUnitsJsonProps {
  id: number;
  name: string;
  audios: IFileProps[];
  answers: IFileProps[];
}

interface IJSONFileProps {
  id: string;
  name: string;
  units: IUnitsJsonProps[];
}

interface IUnitContextData {
  isUnitsLoading: boolean;
  isEmailLoading: boolean;
  units: IUnitsJsonProps[];
  loadUnits(id: string): Promise<void>;
  sendMail(unitName: string): void;
}

const UnitContext = createContext<IUnitContextData>({} as IUnitContextData);

const UnitProvider: React.FC = ({ children }) => {
  const [isUnitsLoading, setIsUnitsLoading] = useState(false);
  const [isEmailLoading, setIsEmailLoading] = useState(false);
  const [units, setUnits] = useState<IUnitsJsonProps[]>(() => {
    const storedUnits = localStorage.getItem('@easyTalkIdiomasOnline:units');

    if (storedUnits) {
      return JSON.parse(storedUnits);
    }
    return [];
  });

  const { loggedUser } = useAuth();
  const { userBook } = useBooks();

  const history = useHistory();

  const loadUnits = async (id: string): Promise<void> => {
    setIsUnitsLoading(true);

    if (userBook) {
      const result: IUnitsJsonProps[] = [];

      if (loggedUser) {
        const res = await fetch(`../${id}.json`, {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });

        const typedUnits: IJSONFileProps = await res.json();

        let foundBook: IBookProps | undefined;

        if (userBook) {
          foundBook = userBook.books.find(book => book.id === id);
        }

        if (foundBook) {
          typedUnits.units.forEach(unit => {
            if (foundBook && foundBook.units.includes(unit.id)) {
              result.push(unit);
            }
          });

          localStorage.setItem(
            '@easyTalkIdiomasOnline:units',
            JSON.stringify(result),
          );
        }
      } else {
        history.push('/');
      }

      setUnits(result);
    }

    setIsUnitsLoading(false);
  };

  const sendMail = (unitName: string): void => {
    setIsEmailLoading(true);

    emailjs.init('user_CoGNXxOTzy3Vwvvgtj0eZ');
    emailjs
      .send('service_pjfy2hp', 'template_xzi8own', {
        studentName: loggedUser?.user,
        moduleName: unitName,
      })
      .then(() => toast.info('Report sent successfully!'))
      .catch(() =>
        toast.error('Oops... error sending report, please try again.'),
      )
      .finally(() => setIsEmailLoading(false));
  };

  return (
    <UnitContext.Provider
      value={{ units, isUnitsLoading, isEmailLoading, loadUnits, sendMail }}
    >
      {children}
    </UnitContext.Provider>
  );
};

function useUnit(): IUnitContextData {
  return useContext(UnitContext);
}

export { useUnit, UnitProvider };

export type { IUnitsJsonProps };
