import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Main from '../pages/Main';
import Units from '../pages/Units';
import Books from '../pages/Books';

import { AuthProvider } from '../hooks/useAuth';
import { UnitProvider } from '../hooks/useUnits';
import { BooksProvider } from '../hooks/useBooks';

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <AuthProvider>
        <BooksProvider>
          <UnitProvider>
            <Route path="/" exact component={Main} />
            <Route path="/books" exact component={Books} />
            <Route path="/books/:id" exact component={Units} />
          </UnitProvider>
        </BooksProvider>
      </AuthProvider>
    </Switch>
  </BrowserRouter>
);

export default Routes;
