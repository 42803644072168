import React, { createContext, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';

import bcrypt from 'bcryptjs';
import base64 from 'base-64';

interface IUserJsonProps {
  name: string;
  user: string;
  pwd?: string;
}

interface IAuthContextData {
  isAuthLoading: boolean;
  loggedUser: IUserJsonProps | undefined;
  login(user: string, pwd: string): Promise<void>;
  signOut(): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [isAuthLoading, setIsAuthLoading] = useState(false);
  const [loggedUser, setLoggedUser] = useState<IUserJsonProps | undefined>(
    () => {
      const storedToken = localStorage.getItem('@easyTalkIdiomasOnline:token');

      if (storedToken) {
        let decoded = base64.decode(storedToken);
        for (let i = 0; i < 5; i++) {
          decoded = base64.decode(decoded);
        }
        return JSON.parse(decoded);
      }
      return undefined;
    },
  );

  const history = useHistory();

  const login = async (user: string, pwd: string): Promise<void> => {
    setIsAuthLoading(true);

    const res = await fetch('control.json', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const students: IUserJsonProps[] = await res.json();

    const foundStudent = students.find(
      student => student.user.toLowerCase() === user.toLowerCase(),
    );

    if (foundStudent && bcrypt.compareSync(pwd, foundStudent.pwd!)) {
      let encoded = base64.encode(
        JSON.stringify({
          name: foundStudent.name,
          user: foundStudent.user,
        }),
      );

      for (let i = 0; i < 5; i++) {
        encoded = base64.encode(encoded);
      }
      localStorage.setItem('@easyTalkIdiomasOnline:token', encoded);

      setLoggedUser(foundStudent);

      history.push('/books');
    } else {
      toast.error('Oops... Wrong username or password.', { autoClose: 3000 });
    }

    setIsAuthLoading(false);
  };

  const signOut = (): void => {
    localStorage.removeItem('@easyTalkIdiomasOnline:token');
    localStorage.removeItem('@easyTalkIdiomasOnline:units');
    history.push('/');
  };

  return (
    <AuthContext.Provider value={{ isAuthLoading, loggedUser, login, signOut }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): IAuthContextData {
  return useContext(AuthContext);
}

export { useAuth, AuthProvider };
