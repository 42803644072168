import React from 'react';

import 'react-toastify/dist/ReactToastify.min.css';

import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import Routes from './routes';

import GlobalStyle from './styles/global';

function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes />
      <GlobalStyle />
    </BrowserRouter>
  );
}

export default App;
