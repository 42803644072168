import React, { createContext, useContext, useState } from 'react';

import { useHistory } from 'react-router-dom';

import { useAuth } from './useAuth';

interface IBookProps {
  id: string;
  name: string;
  units: number[];
}

interface IUserBookProps {
  user: string;
  books: IBookProps[];
}

interface IBooksContextData {
  isBooksLoading: boolean;
  userBook: IUserBookProps | undefined;
  loadBooks(location: string): Promise<void>;
}

const BooksContext = createContext<IBooksContextData>({} as IBooksContextData);

const BooksProvider: React.FC = ({ children }) => {
  const [isBooksLoading, setIsBooksLoading] = useState(false);
  const [userBook, setUserBook] = useState<IUserBookProps | undefined>();

  const { loggedUser } = useAuth();

  const history = useHistory();

  const loadBooks = async (location: string): Promise<void> => {
    setIsBooksLoading(true);

    let result;

    if (loggedUser) {
      const res = await fetch(`${location}userBook.json`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      });

      const typedUserBooks: IUserBookProps[] = await res.json();

      result = typedUserBooks.find(ub => ub.user === loggedUser.user);
    } else {
      history.push('/');
    }

    setUserBook(result);

    setIsBooksLoading(false);
  };

  return (
    <BooksContext.Provider value={{ userBook, isBooksLoading, loadBooks }}>
      {children}
    </BooksContext.Provider>
  );
};

function useBooks(): IBooksContextData {
  return useContext(BooksContext);
}

export { useBooks, BooksProvider };

export type { IBookProps };
