import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
    /* background: #410259; */
    /*background: #580079;*/
    background: #eeeeeeee;
    font: 400 16px Roboto, sans-serif;
  }

  div#container{
    /* background: #580079; */
    min-height: 100vh;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    position: relative;
  }
`;
