import React, { useState } from 'react';

import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import { ThemeProvider } from '@material-ui/core';

import {
  TopoOutside,
  Topo,
  ButtonWrapper,
  fieldStyle,
  MainTitleDiv,
} from './styles';

import back from '../../assets/back.jpg';

import logo from '../../assets/logo.png';

import { useAuth } from '../../hooks/useAuth';
import theme from '../../styles/material';

interface IValidationErrorProps {
  isLoginEmpty: boolean;
  loginMsg: string;
  isPasswordEmpty: boolean;
  passwordMsg: string;
}

const Main: React.FC = (): any => {
  const [user, setUser] = useState('');
  const [pass, setPass] = useState('');

  const { isAuthLoading, login } = useAuth();

  const clearErrors = (): IValidationErrorProps => ({
    isLoginEmpty: false,
    loginMsg: '',
    isPasswordEmpty: false,
    passwordMsg: '',
  });

  const [validation, setValidation] = useState<IValidationErrorProps>(() =>
    clearErrors(),
  );

  const validateSubmit = async (): Promise<void> => {
    if (!user || !pass) {
      setValidation({
        isLoginEmpty: user === '',
        loginMsg: user === '' ? 'Login must be provided.' : '',
        isPasswordEmpty: pass === '',
        passwordMsg: pass === '' ? 'Password must be provided.' : '',
      });
    } else {
      await login(user, pass);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div>
        <TopoOutside img={back} />

        <div id="container">
          <Topo>
            <img src={logo} alt="Easy Talk Idiomas Online" />
          </Topo>
          <MainTitleDiv>
            <h1>Welcome to the drills practice!</h1>
          </MainTitleDiv>
          <Paper style={{ padding: 20, marginTop: 50 }}>
            <TextField
              error={validation.isLoginEmpty}
              helperText={validation.loginMsg}
              fullWidth
              label="Login"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setValidation(clearErrors());
                setUser(e.target.value);
              }}
              onKeyPress={(key: React.KeyboardEvent<any>): void => {
                if (key.charCode === 13) {
                  validateSubmit();
                }
              }}
            />
            <TextField
              error={validation.isPasswordEmpty}
              helperText={validation.passwordMsg}
              fullWidth
              label="Password"
              type="password"
              style={fieldStyle}
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setValidation(clearErrors());
                setPass(e.target.value);
              }}
              onKeyPress={(key: React.KeyboardEvent<any>): void => {
                if (key.charCode === 13) {
                  validateSubmit();
                }
              }}
            />
            <ButtonWrapper>
              <Button
                disabled={isAuthLoading}
                variant="contained"
                color="primary"
                onClick={validateSubmit}
              >
                {isAuthLoading ? <CircularProgress /> : 'Login'}
              </Button>
            </ButtonWrapper>
          </Paper>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default Main;
